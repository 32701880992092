import React from 'react';

import Layout from '../components/Layout';

const OurValues = () => {
  return (
    <section>
      <Layout className="whats-on">
        <img />
      </Layout>
    </section>
  );
};

export default OurValues;
